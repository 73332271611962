<template>
  <div v-if="isPermitted" class="d-inline">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    permission: { type: String, default: '' }
  },
  computed: {
    isPermitted () {
      return this.user_permissions.length === 0 || this.user_permissions.includes(this.permission)
    },
    user_permissions () { // auth user user_permissions
        return this.$store.state.auth.user
        ? this.$store.state.auth.user.all_permissions
        : null;
    }
  }
}
</script>